<template>
  <div class="layout">
    1111111
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  }
};
</script>
<style scoped>
</style>
